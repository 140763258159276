<template>
  <div></div>
</template>
<script>
export default {
  name: "Geolocation",
  methods: {
    getLocation() {
      this.$getLocation({
        enableHighAccuracy: false, //defaults to false
        timeout: Infinity, //defaults to Infinity
        maximumAge: 0, //defaults to 0
      }).then((coordinates) => {
        alert(coordinates);
      });
    },
  },
  created() {
    this.getLocation;
  },
};
</script>
